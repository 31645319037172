import styled, { css } from 'styled-components'
import { rgba } from 'polished'

import { transition, fieldReset } from '../../common/mixins'
import { FIELD_TYPES } from '../../constants/fieldTypes'

const StyledFieldLabel = styled.label`
  display: inline-flex;
  color: ${({ theme }) => theme.colors.white};
  ${transition}
`

const StyledFieldControl = styled.div`
  position: relative;
  display: flex;

  input,
  select,
  textarea {
    ${fieldReset}
    ${transition}

    &:-webkit-autofill {
      -webkit-box-shadow: 0 0 0px 1000px transparent inset;
      transition: background-color 5000s ease-in-out 0s;
    }

    & + ${StyledFieldLabel} {
      position: absolute;
      top: -0.625rem;
      left: 1rem;
      font-size: 0.875rem;
      padding: 0 0.5rem;
    }

    &:focus {
      border-color: ${({ theme }) => theme.colors.yellow};
      box-shadow: 0 0 0.125rem 0 ${({ theme }) => theme.colors.yellow};

      & + ${StyledFieldLabel} {
        color: ${({ theme }) => theme.colors.yellow};
      }
    }
  }

  ${({ fieldType }) =>
    fieldType === FIELD_TYPES.newsletter &&
    css`
      background: ${({ theme }) => theme.colors.white};
    `}
      
  input, select, textarea {
    ${({ fieldType }) =>
      fieldType === FIELD_TYPES.light &&
      css`
        color: ${({ theme }) => theme.colors.white};
        border: 1px solid ${({ theme }) => rgba(theme.colors.white, 0.5)};

        &:-webkit-autofill {
          -webkit-text-fill-color: ${({ theme }) => theme.colors.white};
        }

        & + label {
          color: ${({ theme }) => theme.colors.white};
          background: ${({ theme }) => theme.colors.darkGrey};
        }
      `}

    ${({ fieldType }) =>
      fieldType === FIELD_TYPES.dark &&
      css`
        border: 1px solid ${({ theme }) => theme.colors.black};

        &:-webkit-autofill {
          -webkit-text-fill-color: ${({ theme }) => theme.colors.black};
        }

        & + label {
          background: ${({ theme }) => theme.colors.white};
          color: ${({ theme }) => theme.colors.black};
        }
      `}

    ${({ fieldType }) =>
      fieldType === FIELD_TYPES.newsletter &&
      css`
        color: ${({ theme }) => theme.colors.black};
        background: inherit;
        border: none;

        & + label {
          display: none;
        }
      `}
    }

    select option {
      color: ${({ theme }) => theme.colors.black};
    }


    ${({ validate }) =>
      validate &&
      css`
        input,
        select,
        textarea {
          border: 1px solid ${({ theme }) => theme.colors.error};
        }
      `}
  `

const StyledField = styled.div`
  position: relative;
  width: 100%;
  ${transition}

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;
      pointer-events: none;
    `}
`

const StyledFieldMessage = styled.div`
  margin-top: 0.125rem;
`

const StyledChooseFieldGroup = styled.div`
  position: relative;
  align-items: center;
  z-index: 1;
`

const StyledChooseFieldLabel = styled(StyledFieldLabel)`
  padding-left: 1.5rem;
  z-index: 1;
  width: 100%;
  cursor: pointer;

  &::before,
  &::after {
    content: '';
    position: absolute;
    display: flex;
    flex-shrink: 0;
  }

  &::before {
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    border: 1px solid ${({ theme }) => theme.colors.yellow};
    width: 1rem;
    height: 1rem;
    border-radius: 3.125rem;
  }

  &::after {
    opacity: 0;
    ${transition({ property: 'opacity', duration: '170ms' })}
  }
`

const StyledChooseFieldInput = styled.input`
  position: absolute;
  top: -100vh;
  left: -100vw;
  opacity: 0;
  height: 0;
  width: 0;
  visibility: hidden;

  &:checked + ${StyledChooseFieldLabel}::after {
    opacity: 1;
  }
`

export {
  StyledField,
  StyledFieldControl,
  StyledFieldLabel,
  StyledFieldMessage,
  StyledChooseFieldGroup,
  StyledChooseFieldLabel,
  StyledChooseFieldInput,
}
