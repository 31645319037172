import React from 'react'
import PropTypes from 'prop-types'

import { StyledField, StyledFieldControl, StyledFieldLabel } from '../styles'
import { StyledInput } from './styles'
import { FIELD_TYPES } from '../../../constants/fieldTypes'

const Input = ({
  fieldType,
  label,
  id,
  inputType,
  name,
  placeholder,
  validate,
  disabled,
  ...input
}) => (
  <StyledField {...{ disabled }}>
    <StyledFieldControl {...{ fieldType }} validate={!!validate}>
      <StyledInput
        type={inputType}
        onPaste={(e) => e.preventDefault()}
        {...{ id, placeholder, ...input }}
      />
      <StyledFieldLabel htmlFor={id}>{label}</StyledFieldLabel>
    </StyledFieldControl>
  </StyledField>
)

Input.defaultProps = {
  fieldType: FIELD_TYPES.light,
  inputType: 'text',
  disabled: false,
}

Input.propTypes = {
  fieldType: PropTypes.oneOf(Object.keys(FIELD_TYPES)),
  name: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  inputType: PropTypes.string,
  message: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  validate: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  disabled: PropTypes.bool,
}

export default Input
